@charset "UTF-8";
.sh-fixed-menu {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #1f2532;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%); }
  .sh-fixed-menu a {
    color: white;
    cursor: pointer; }
    .sh-fixed-menu a:hover {
      color: white; }
  .sh-fixed-menu.sh-fixed-menu-is-active {
    transform: translateY(0%); }
  .sh-fixed-menu.sh-fixed-menu-is-active .sh-fixed-menu-toggle {
    display: block;
    background-color: #404657; }
  .sh-fixed-menu .sh-fixed-menu-toggle {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 3px 8px;
    color: #fff;
    background-color: #1f2532;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0 3px 0 0;
    transition: background 0.3s; }
    .sh-fixed-menu .sh-fixed-menu-toggle:hover {
      background-color: #404657; }

.sh-fixed-menu-back {
  float: left;
  display: block;
  width: 60px;
  line-height: 43px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main {
  text-align: center; }

.sh-fixed-menu-nav::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  clear: both; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li {
  padding-top: 10px;
  padding-bottom: 10px; }

.sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li {
  display: inline-block; }
  .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li a {
    color: #9aa2b8;
    font-size: 14px; }
    .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li a:hover {
      color: white; }
  .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li a.sh-fixed-menu-current-page {
    color: white;
    cursor: text; }
  .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li::after {
    content: '|';
    padding: 0 10px;
    display: inline-block;
    font-size: 13px;
    color: #697080; }
  .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li:last-child:after {
    display: none; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li ul {
  display: block;
  /* HIDE HERE */
  display: none; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li ul:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1f2532;
  position: absolute;
  top: 100%;
  margin-top: -10px;
  margin-left: 30px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li > ul li ul {
  margin-bottom: -10px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li ul {
  padding: 10px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li > ul li {
  padding: 3px 0px 7px;
  margin-right: -2px;
  background-color: #1f2532; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li > ul li:first-child {
  padding-left: 13px;
  border-radius: 3px 0 0 3px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li > ul li:last-child {
  padding-right: 13px;
  border-radius: 0 3px 3px 0; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main li:hover > ul {
  display: block; }

.sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li > ul {
  position: absolute;
  bottom: 100%;
  white-space: nowrap; }

.sh-fixed-menu-nav-prev-next {
  float: right;
  text-align: right; }
  .sh-fixed-menu-nav-prev-next li {
    display: inline-block;
    padding: 0 15px;
    margin: 10px 0; }
    .sh-fixed-menu-nav-prev-next li::last-child {
      padding-right: 0; }
    .sh-fixed-menu-nav-prev-next li a {
      color: #9aa2b8;
      font-size: 14px; }
    .sh-fixed-menu-nav-prev-next li:hover::after {
      content: attr(data-page);
      display: inline-block;
      padding: 4px 8px;
      white-space: nowrap;
      font-size: 13px;
      color: white;
      border-radius: 3px;
      background-color: #1f2532;
      position: absolute;
      margin-top: -45px;
      transform: translate(-100%, 0); }
  .sh-fixed-menu-nav-prev-next li.sh-fixed-menu-nav-prev a:hover:before,
  .sh-fixed-menu-nav-prev-next li.sh-fixed-menu-nav-next a:hover:after {
    color: white; }
  .sh-fixed-menu-nav-prev-next li.sh-fixed-menu-nav-prev a:before {
    content: '‹';
    padding: 0 10px 0 0;
    display: inline-block;
    font-size: 13px;
    color: #697080; }
  .sh-fixed-menu-nav-prev-next li.sh-fixed-menu-nav-next a:after {
    content: '›';
    padding: 0 0 0 10px;
    display: inline-block;
    font-size: 13px;
    color: #697080;
    transition: color 0.2s; }
